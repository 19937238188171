<template>
  <div class="badge-page user-page">
    <div class="content-wrapper">
      <h2>{{ campaignName }}</h2>
    </div>
    <b-card>
      <draggable :list="tree" handle=".kpi-handle" @change="postNewPosition" ghost-class="ghost-item">
        <div v-for="badge in tree" :key="badge.id" class="align-items-center py-4 row-divider d-flex">
          <b-col lg="6">
            <div class="d-flex align-items-center">
              <img class="circle img-fit flex-shrink-0 mr-4" :src="badge.url_image" />
              <div>
                <h4 class="mb-1">{{ badge.title["it-IT"] }}</h4>
                <b-badge class="badge-text">{{
                    getBadgeTypeName(badge.id_tipo)
                }}</b-badge>
                <p class="pb-0 pt-2">
                  {{ cutDescrizione(badge.description["it-IT"]) }}
                </p>
              </div>
            </div>
          </b-col>
          <b-col lg="2" class="text-center">
            <h1 class="mb-1">{{ badge.numeroAzioni }}</h1>
            <h6 class="m-0">Actions to be concluded</h6>
          </b-col>
          <b-col lg="2" class="text-center">
            <h1 class="mb-1">
              {{ badge.numeroAzioni * badge.punti_per_azione }}
            </h1>
            <h6 class="m-0">Points earned</h6>
          </b-col>
          <b-col lg="2" class="text-center h4 text-muted">
            <b-icon icon="arrows-move" class="pointer kpi-handle mr-3"></b-icon>
            <b-icon @click="
  currentItem = badge;
tmpItem = JSON.parse(JSON.stringify(badge));
            " icon="gear-fill" class="pointer mr-3" v-b-toggle.sidebar-badge></b-icon>
            <b-icon @click="deleteItem(badge.id)" icon="trash-fill" class="pointer"></b-icon>
          </b-col>
        </div>
      </draggable>

      <b-button squared size="lg" class="button-dashed big-button mt-5" v-b-toggle.sidebar-badge>
        <b-icon-plus />Add Badge
      </b-button>
    </b-card>
    <div class="content-wrapper" v-if="hasNextRoute()">
      <b-button squared variant="primary" size="lg" @click="toNextRoute()">Save and continue</b-button>
    </div>
    <b-button v-if="isDebug" class="fixed-button py-2" v-b-toggle.sidebar-iframe>
      <img src="../../assets/images/phone.png" class="img-fluid" />
    </b-button>
    <b-sidebar @hidden="resetDataSidebarIframe" class="right-sidebar iframe-sidebar" id="sidebar-iframe" width="500px"
      right shadow lazy>
      <div class="login-as">
        <h4>Login as:</h4>
        <b-select @change="getUrlDemo" :options="usersList" v-model="userSelected">
        </b-select>
        <!-- user selectd: {{ userSelected }} || url demo: {{ url_demo }} -->
      </div>
      <div class="iframe-phone">
        <iframe class="iframe-phone-iframe" :src="url_demo" frameborder="0"></iframe>
      </div>
    </b-sidebar>
    <b-sidebar class="right-sidebar" id="sidebar-badge" right shadow lazy @hidden="resetSidebar" @shown="setAzioni">
      <div class="content-wrapper my-3 mx-1">
        <div class="d-flex align-items-center mb-5">
          <div class="upload-photo-cicrle" v-bind:style="{
            backgroundImage: 'url(' + backgroundImage() + ')',
          }">
            <b-form-file class="button-file photo-button" v-model="file_photo" @input="updatePhotoURL"
              accept="image/jpeg, image/png, image/gif">
              <template #placeholder>
                <b-icon-camera-fill />
              </template>
              <template #file-name>
                <b-icon-camera-fill />
              </template>
            </b-form-file>
          </div>
          <h4 v-if="tmpItem.id" class="m-0 ml-4">Edit Badge</h4>
          <h4 v-else class="m-0 ml-4">New Badge</h4>
        </div>
        <b-form-group label="Title" label-for="title">
          <b-form-input v-model="tmpItem.title[title_lang]" />
          <LangOptions v-model="title_lang" :options="languages" />
        </b-form-group>
        <b-form-group label="Description" label-for="description-en">
          <b-form-textarea v-model="tmpItem.description[description_lang]" rows="3" />
          <LangOptions v-model="description_lang" :options="languages" />
        </b-form-group>
        <b-form-group class="mt-5 mb-2">
          <template #label>
            Select the type and the actions to be completed to win the badge
            <InfoPopover class="ml-1" text="Select the type and the actions to win this badge " />
          </template>
          <div class="py-2 px-2 bg-gray">
            <b-form-radio-group v-model="tmpItem.id_tipo" :options="type_options" @change="
  list_azioni_selezionate = [];

azioni_pesi = {};
            "></b-form-radio-group>
          </div>
          <div class="checkbox-container">
            <b-row v-for="option in options[tmpItem.id_tipo]" :key="option.value" class="p-2 align-items-center"
              style="min-height: 3rem">
              <b-col md="3">
                <b-form-checkbox :disabled="disableCheckboxes == true && option.id != 0"
                  v-model="list_azioni_selezionate" :value="option.id" @change="updatePesi($event)">
                  <span class="ml-3 text-muted text-center">
                    {{ option.actions }} action{{
                        option.actions != 1 ? "s" : ""
                    }}
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col md="auto">
                <span v-if="option.viewMode == 'supervisor'" class="mr-2 text-muted kpis-label" style="width: 2rem">
                  <b-icon-diagram-3-fill></b-icon-diagram-3-fill>
                </span>

                <span v-else class="mr-2 kpis-label">
                  <span class="ml-2 text-muted kpis-label" style="width: 2rem">
                    <b-icon-person-fill v-if="
                      option.viewMode_showme == true ||
                      option.viewMode == 'singola'
                    "></b-icon-person-fill>
                    <b-icon-person v-else></b-icon-person>
                  </span>

                  <span class="ml-2 text-muted kpis-label" style="width: 2rem">
                    <b-icon-people v-if="option.viewMode == 'singola'"></b-icon-people>
                    <b-icon-people-fill v-else></b-icon-people-fill>
                  </span>
                </span>
                <strong>
                  {{ option.nome }}
                </strong>
              </b-col>
              <b-col md="3">
                <strong>
                  <b-form-input v-if="list_azioni_selezionate.includes(option.id)" v-model="azioni_pesi[option.id]"
                    type="number" placeholder="Actions" size="sm" @input="list_azioni_selezionate.splice()">
                  </b-form-input>
                </strong>
              </b-col>
            </b-row>
          </div>
        </b-form-group>
        <span>You selected
          <strong>{{ azioni_selezionate }} action{{
              azioni_selezionate != 1 ? "s" : ""
          }}</strong>
          to be completed to win the badge</span>
        <div class="p-4 bg-gray mt-5">
          <div class="d-flex align-items-center">
            <span>For every action completed get:</span>
            <b-form-input type="number" no-wheel v-model="tmpItem.punti_per_azione" style="width: 5rem" class="ml-2">
            </b-form-input>
            <span class="ml-2">Points</span>
            <span class="ml-2">X</span>
            <span class="ml-2">{{ azioni_selezionate }}</span>
            <span class="ml-2">=</span>
            <h4 class="m-0">
              <b-badge pill variant="secondary" class="ml-2">{{
                  totale_punti
              }}</b-badge>
            </h4>
          </div>

          <b-form-checkbox v-model="tmpItem.check_max_utenti" class="mt-5">
            <div>
              Assign this badge to a maximum number of users
              <!-- <InfoPopover class="ml-1" text="link ecc." /> -->
            </div>
            <div style="font-size: 12px">
              The first who conclude the actions
            </div>
          </b-form-checkbox>
          <b-input-group v-show="tmpItem.check_max_utenti" class="ml-4 mt-2" style="width: 6rem">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="people-fill" />
              </b-input-group-text>
            </template>
            <b-form-input type="number" no-wheel v-model="tmpItem.max_utenti"></b-form-input>
          </b-input-group>

          <!-- <b-form-checkbox v-model="tmpItem.abilita_notifica" class="mt-3">
            <div>
              Enable sending notification of badge assignment
              <InfoPopover class="ml-1" text="descrizione ecc." />
            </div>
          </b-form-checkbox>
          <b-form-select
            v-show="tmpItem.abilita_notifica"
            v-model="tmpItem.tipo_notifica"
            :options="abilita_notifica_options"
            class="ml-4 mt-2"
            style="width: 15rem"
          ></b-form-select> -->
          <!-- <b-form-checkbox v-model="tmpItem.abilita_feedback" class="mt-3">
            <div>
              Enable sending feedback request
              <InfoPopover class="ml-1" text="descrizione ecc." />
            </div>
          </b-form-checkbox> -->
          <b-form-checkbox v-model="tmpItem.raddoppio_punti" class="mt-3">
            <div>
              Allow doubling points
              <InfoPopover class="ml-1"
                text="Make this badge one of the specific badges selectables in 'Mission -> Allow badge assigning'  " />
            </div>
          </b-form-checkbox>
        </div>
        <div class="mt-5 mb-5" style="display:flex;flex-direction:column;align-items:flex-start">
          <b-button v-if="isLoading" squared variant="primary" size="lg" class="big-button" disabled>
            <b-spinner small type="grow"></b-spinner> Loading...
          </b-button>
          <b-button :disabled="!isValidBadge" v-else squared variant="primary" size="lg" class="big-button"
            @click="saveItem">Save</b-button>
          <span class="warning-text" v-if="!isValidBadge">Please select at least <strong>one action</strong> to activate
            a valid Badge</span>
        </div>
        <div class="mt-5 mb-5"></div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService";
import draggable from "vuedraggable";

import * as CampaignRoutes from "@/utils/CampaignRoutes.js";
export default {
  components: { draggable },
  data() {
    return {
      campaignName: "",

      isDebug: null,
      disableCheckboxes: false,

      idCampaign: this.$route.params.campaignId,
      options: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
      },

      badges_list: [],
      tree: [],

      currentItem: null,
      tmpItem: {},

      title_lang: "",
      description_lang: "",
      file_photo_url: "",
      file_photo: null,

      type_options: [
        { value: 0, text: "Learning" },
        { value: 1, text: "Kpis" },
        { value: 2, text: "Task", disabled: true },
        { value: 3, text: "Landing pages" },
        { value: 4, text: "Social" },
      ],

      abilita_notifica_options: [
        { text: "Tutto il team", value: 0 },
        { text: "Solo all'utente", value: 1 },
      ],

      isLoading: false,

      list_azioni_selezionate: [],
      azioni_pesi: {},

      isNewCampaign: this.$route.params.campaignId == null,

      url_demo: "",
      usersList: [{ value: null, text: "Seleziona Utente" }],
      userSelected: null,
    };
  },
  service: null,
  campaignService: null,
  created() {
    this.service = new AxiosService("Badge");
    this.campaignService = new AxiosService("Campaign");
    this.resetSidebar();

    // this.service.readCustomEndpoint("Setting").then((res) => {
    //   if (res) {
    //     this.url_demo = res.url_demo;
    //   } else {
    //     console.log("ERROr calling Setting");
    //   }
    // });
    this.isDebugFunction();
  },
  mounted() {
    if (!this.isNewCampaign) {
      this.getUsersByCampaign();
    }
    this.campaignService
      .readById(this.$route.params.campaignId)
      .then((data) => {
        this.campaignName = data.title[this.default_lang.value];
      });
    let params = [{ param: "idCampagna", value: this.idCampaign }];
    this.service.read(params).then((res) => {
      res.forEach((element) => {
        this.badges_list.push(element);
      });
      this.tree = this.buildTree(this.badges_list);
    });
    this.service
      .getActionUtility("GetLearningActions", this.idCampaign)
      .then((res) => {
        this.options[0] = res;
      });
    this.service
      .readCustomEndpoint("KpiFromGlobal?idCampagna=" + this.idCampaign)
      .then((res) => {
        let arr = res.map((x) => {
          return {
            actions: x.numero_azioni,
            nome: x.friendly_name["it-IT"],
            id: x.id,
            defaultActionValue: x.ranking_points,
            viewMode: x.viewMode,
            viewMode_showme: x.viewMode_showme,
            // ...x,
          };
        });
        this.options[1] = arr;
      });
    this.service
      .getActionUtility("GetLandingActions", this.idCampaign)
      .then((res) => {
        this.options[3] = res;
      });
    this.service
      .getActionUtility("GetSocialActions", this.idCampaign)
      .then((res) => {
        this.options[4] = res;
      });
  },
  methods: {
    getUsersByCampaign() {
      this.usersList.splice(1);
      if (this.$route.params.campaignId) {
        this.service
          .readCustomEndpoint(
            "CampaignManager/GetUsersInCampaign",
            this.$route.params.campaignId
          )
          .then((res) => {
            res.forEach((element) => {
              this.usersList.push(element);
            });
          });
      } else {
        console.log("FAIL ");
      }
    },
    getUrlDemo() {
      if (this.userSelected != null) {
        this.service.readCustomEndpoint("Setting").then((res) => {
          if (res) {
            this.url_demo =
              res.url_demo +
              "/debug/" +
              this.$route.params.campaignId +
              "/" +
              this.userSelected +
              "/badge/"
              + this.campaignName;
          } else {
            console.err("ERROr calling Setting");
          }
        });
      } else {
        this.url_demo = "";
        // this.service.readCustomEndpoint("Setting").then((res) => {
        //   this.url_demo = res.url_demo + "login";
        // });
      }
    },
    resetDataSidebarIframe() {
      this.userSelected = null;
      this.url_demo = "";
    },
    isDebugFunction() {
      this.isDebug = null;
      this.service
        .readCustomEndpoint(
          "CampaignManager/IsDebugEnabledOnCampaign/" +
          this.$route.params.campaignId
        )
        .then((res) => {
          this.isDebug = res;
          console.log("IS DEBUG? ", this.isDebug);
        });
    },
    getBadgeTypeName(id) {
      const badgeTypeName = this.type_options.find((x) => x.value === id);
      return badgeTypeName ? badgeTypeName.text : "";
    },
    cutDescrizione(descrizione) {
      if (descrizione) {
        if (descrizione.lenght <= 100) return descrizione;
        return descrizione.substring(0, 100).trim() + "..";
      }
    },
    updatePhotoURL() {
      this.file_photo_url = URL.createObjectURL(this.file_photo) || "";
    },
    resetSidebar() {
      this.tmpItem = {
        id: 0,
        id_tipo: 0,
        url_image: this.$driveAddress + "Common/badge.svg",
        title: {},
        description: {},
        lista_azioni: [],
        abilita_feedback: false,
        punti_per_azione: 0,
        raddoppio_punti: false,
        check_max_utenti: false,
        max_utenti: 0,
        abilita_notifica: false,
        tipo_notifica: 0,
        numeroAzioni: 0,
      };
      this.currentItem = null;
      this.file_photo_url = "";
      this.file_photo = null;
      this.title_lang = this.default_lang.value;
      this.description_lang = this.default_lang.value;
      this.list_azioni_selezionate = [];
      this.list_azioni_selezionate_withName = [];
      this.azioni_pesi = {};
    },
    backgroundImage() {
      return this.file_photo_url || this.tmpItem.url_image;
    },
    deleteItem(id) {
      this.service
        .delete(id)
        .then((res) => {
          if (res) {
            this.badges_list = this.badges_list.filter(
              (currentItem) => id !== currentItem.id
            );
            this.tree = this.buildTree(this.badges_list);
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    async saveItem() {
      this.isLoading = true;
      let item = { ...this.tmpItem };

      const validationObject = this.$validate("badge", item);
      if (!validationObject.validation) {
        this.isLoading = false;
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      let params = [{ param: "idCampagna", value: this.idCampaign }];

      if (this.file_photo) {
        const formData = new FormData();
        formData.append("pic", this.file_photo);
        let imageName = await this.service
          .uploadFile(formData)
          .then((res) => res.data);
        let imageUrl = this.$driveAddress + imageName;
        item.url_image = imageUrl.replaceAll(" ", "%20");
      }

      item.lista_azioni = [];
      for (const [key, value] of Object.entries(this.azioni_pesi)) {
        const id = parseInt(key);
        let numeroAzioni = parseInt(value);
        const option = this.options[item.id_tipo].find((x) => x.id == id);
        const azioniMax = option ? option.actions || 0 : 0;
        if (
          isNaN(numeroAzioni) ||
          numeroAzioni < 0
          //      ||
          //     numeroAzioni > azioniMax
        ) {
          numeroAzioni = azioniMax;
        }
        if (id != null) {
          item.lista_azioni.push({ id: id, numeroAzioni: numeroAzioni });
        }
      }
      item.numeroAzioni = this.azioni_selezionate;

      if (item.id) {
        this.service
          .update(item)
          .then((res) => {
            if (res.id == item.id) {
              Object.keys(this.currentItem).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentItem[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-badge");
              this.tree = this.buildTree(this.badges_list);
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.service
          .create(item, params)
          .then((res) => {
            if (res.id) {
              item.id = res.id;
              this.badges_list.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-badge");
              this.tree = this.buildTree(this.badges_list);
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
      this.badges_list.splice(0);
      this.service.read(params).then((res) => {
        res.forEach((element) => {
          this.badges_list.push(element);
        });
        this.tree = this.buildTree(this.badges_list);
      });
    },
    buildTree() {
      let list = JSON.parse(JSON.stringify(this.badges_list));
      if (!list) return [];
      let map = {},
        node,
        roots = [],
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.id_padre && node.id_padre !== "0") {
          if (Object.prototype.hasOwnProperty.call(map, node.id_padre)) {
            list[map[node.id_padre]].children.push(node);
          }
          //else nodo orfano
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    flattenTree(children, parent) {
      return Array.prototype.concat.apply(
        children.map((x) => {
          x.id_padre = parent || 0;
          return x;
        }),
        children.map((x) => this.flattenTree(x.children || [], x.id))
      );
    },
    postNewPosition() {
      let newOrder = [];
      for (let i = 0; i < this.tree.length; i++) {
        newOrder.push({ ID: this.tree[i].id, Ordine: i });
      }
      this.service
        .updateOrder(newOrder)
        .then(() => {
          this.$successToast();
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    hasNextRoute() {
      return CampaignRoutes.hasNextRoute(this, "Badges");
    },
    toNextRoute() {
      CampaignRoutes.toNextRoute(this, "Badges");
    },
    updatePesi(event) {
      //check if action with id==0 is checked (action.id == 0 is "all actions")
      let hasZeroId = Object.keys(event)
        .map((element) => event[element])
        .filter((x) => x == 0).length;

      if (hasZeroId) {
        this.disableCheckboxes = true;
        this.list_azioni_selezionate.length = 0;
        this.list_azioni_selezionate.push(0);
      } else {
        this.disableCheckboxes = false;
      }

      const toRemove = Object.keys(this.azioni_pesi).filter((x) => {
        const numX = parseInt(x);

        if (Number.isInteger(numX))
          return !this.list_azioni_selezionate.includes(numX);
        else return false;
      });

      toRemove.forEach((x) => delete this.azioni_pesi[x]);

      this.list_azioni_selezionate.forEach((x) => {
        if (this.azioni_pesi[x] === undefined) this.azioni_pesi[x] = 0;
      });
    },
    setAzioni() {
      this.tmpItem.lista_azioni.forEach((x) => {
        this.list_azioni_selezionate.push(x.id);
        this.azioni_pesi[x.id] = x.numeroAzioni;
      });
    },
  },

  // {
  //       id: 0,
  //       id_tipo: 0,
  //       url_image: this.$driveAddress + "Common/badge.svg",
  //       title: {},
  //       description: {},
  //       lista_azioni: [],
  //       abilita_feedback: false,
  //       punti_per_azione: 0,
  //       raddoppio_punti: false,
  //       check_max_utenti: false,
  //       max_utenti: 0,
  //       abilita_notifica: false,
  //       tipo_notifica: 0,
  //       numeroAzioni: 0,
  //     }
  computed: {
    isValidBadge() {
      if (this.azioni_selezionate == 0) {
        return false
      } else {
        return true
      }
    },
    azioni_selezionate() {
      return this.options[this.tmpItem.id_tipo].reduce((sum, current) => {
        if (this.list_azioni_selezionate.includes(current.id)) {
          let actions = current.actions;
          if (this.azioni_pesi[current.id]) {
            let tmpActions = parseInt(this.azioni_pesi[current.id]);
            if (
              !isNaN(tmpActions) &&
              tmpActions >= 0
              //   &&
              //   tmpActions <= actions
            ) {
              actions = tmpActions;
            }
          }
          return sum + actions;
        } else {
          return sum;
        }
      }, 0);
    },
    totale_punti() {
      return (this.tmpItem.punti_per_azione || 0) * this.azioni_selezionate;
    },
    languages() {
      return this.$parent.companyLanguages;
    },
    default_lang() {
      return this.$parent.default_lang;
    },
  },
};
</script>